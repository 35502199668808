import * as React from "react"
import Layout from "../components/layout"

import TeamMember from "../modules/teamMember";
import Investors from "../modules/investors";
import Button from "../modules/button";

const TeamPage = () => {
    return (
        <Layout className={'team'} title={'Team'}>
            <section className={'team-section'}>
                <div className={'container'}>

                    <h1>Team</h1>

                    <div className={'row mx-lg-n5'}>
                        <div className={'col col-12 col-md-6 col-lg-4 py-4 px-3 px-xl-5'}>
                            <TeamMember name={'Penumbra Labs'} text={'Penumbra Labs is a contributor to the Penumbra protocol and core client development.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-4 py-4 px-3 px-xl-5'}>
                            <TeamMember name={'Strangelove'} text={'Strangelove is a contributor to the Penumbra IBC relaying and testing infrastructure.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-4 py-4 px-3 px-xl-5'}>
                            <TeamMember name={'Zpoken'} text={'Zpoken is a contributor to the Penumbra web and extension infrastructure.'}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'careers-section'}>
                <div className={'container'}>

                    <h1 className={'text-center thin color'}>Come work with us</h1>

                    <div className={'text'}>
                        Penumbra brings privacy to proof-of-stake, allowing users to transact, stake, swap, and marketmake without disclosing their personal information, account activity, or trading strategies to the entire world – while still permitting selective disclosure to appropriate parties. It takes an ecosystem of teams to develop a decentralized, public network. Working with a contributor team is a unique opportunity to solve exciting problems and build new financial infrastructure in a collaborative, public work environment, and to have a meaningful stake in the creation of a new protocol.    
                    </div>

                    <Button url={'https://boards.greenhouse.io/penumbralabs'} class={'centered'} title={'Penumbra Labs Careers'}/>
                </div>
            </section>
            <section className={'investors-section'}>
                <div className={'container'}>
                    <Investors/>
                </div>
            </section>

        </Layout>
    )
}

export default TeamPage
