import * as React from "react"
import Image from "../modules/imageComponent";

export default class TeamMember extends React.Component {
    render() {
        return <div className={'member'}>
            <div className={'image'}>
                <Image alt={this.props.name} filename={this.props.image} />
            </div>
            <div className={'text'}>
                <div className={'name'}>{this.props.name}</div>
                <div className={'text'}>{this.props.text}</div>
            </div>
        </div>
    }
}
